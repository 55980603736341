<template>
    <div>
        <Navbar jenis="biasa"/>
        <main id="content" style="transform: none;margin-top: 10rem!important">
                <div class="content-widget" style="transform: none;">
                    <div class="container" style="transform: none;">
                        <div class="row" style="transform: none;">
                            <div class="col-md-8">
                                <h4 class="spanborder">
                                    <span>Category - {{ category }} </span>
                                </h4>
                                <h5 class="text-center" style="color: #c2bdbd!important; font-size: 18px" v-if="!random">
                                    No post here, u wanna create some post <a href="/iam/write">Click Me</a>
                                </h5>
                                <template v-if="random">
                                    <article class="first mb-3">
                                        <figure><a :href="`/${random.url}`"><img :src="random.thumbail_url" style="width: 100%" alt="post-title"></a></figure>
                                        <h1 class="entry-title mb-2"><router-link :to="`/${random.url}`">{{ random.title }}</router-link></h1>
                                        <div class="entry-excerpt mb-3">
                                            <p style="margin-bottom: 0;">
                                            {{ random.description }}
                                            </p>
                                        </div>
                                        <div class="entry-meta align-items-center">
                                            <a class="author-avatar" href="#"><img :src="random.owner_image" alt=""></a>
                                            <a href="author.html">{{ random.owner }}</a> in <a href="/">Random</a><br>                                  
                                            <span>{{ random.updated_date }}</span>
                                            <span class="middotDivider"></span>
                                            <span class="readingTime" :title="random.reading.text">{{ random.reading.text }}</span>
                                            <span class="svgIcon svgIcon--star">
                                                <svg class="svgIcon-use" width="15" height="15">
                                                    <path d="M7.438 2.324c.034-.099.09-.099.123 0l1.2 3.53a.29.29 0 0 0 .26.19h3.884c.11 0 .127.049.038.111L9.8 8.327a.271.271 0 0 0-.099.291l1.2 3.53c.034.1-.011.131-.098.069l-3.142-2.18a.303.303 0 0 0-.32 0l-3.145 2.182c-.087.06-.132.03-.099-.068l1.2-3.53a.271.271 0 0 0-.098-.292L2.056 6.146c-.087-.06-.071-.112.038-.112h3.884a.29.29 0 0 0 .26-.19l1.2-3.52z"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </article> 
                                    <div class="divider"></div>                
                                    <article class="row justify-content-between mb-5 mr-0" v-for="item in data" :key="item.index">
                                        <div class="col-md-9 ">
                                            <div class="align-self-center">
                                                <div class="capsSubtle mb-2">Random Pick</div>
                                                <h3 class="entry-title mb-3"><router-link :to="`/${item.url}`">{{ item.title }}</router-link></h3>
                                                <div class="entry-excerpt">
                                                    <p>
                                                    {{ item.description }}
                                                    </p>
                                                </div>
                                                <div class="entry-meta align-items-center">
                                                    <a href="author.html">{{ item.owner }}</a> in <a href="archive.html">OneZero</a><br>                                    
                                                    <span>{{ item.updated_date }}</span>
                                                    <span class="middotDivider"></span>
                                                    <span class="readingTime" :title="item.reading.text">{{ item.reading.text }}</span>
                                                    <span class="svgIcon svgIcon--star">
                                                        <svg class="svgIcon-use" width="15" height="15">
                                                            <path d="M7.438 2.324c.034-.099.09-.099.123 0l1.2 3.53a.29.29 0 0 0 .26.19h3.884c.11 0 .127.049.038.111L9.8 8.327a.271.271 0 0 0-.099.291l1.2 3.53c.034.1-.011.131-.098.069l-3.142-2.18a.303.303 0 0 0-.32 0l-3.145 2.182c-.087.06-.132.03-.099-.068l1.2-3.53a.271.271 0 0 0-.098-.292L2.056 6.146c-.087-.06-.071-.112.038-.112h3.884a.29.29 0 0 0 .26-.19l1.2-3.52z"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 bgcover">
                                            <img :src="item.thumbail_url" alt="">
                                        </div>
                                    </article>
                                </template>
                            </div> <!--col-md-8-->
                            <div class="col-md-4 pl-md-5 sticky-sidebar" style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">                    
                                
                            <div class="theiaStickySidebar" style="padding-top: 1px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 913.5px;"><div class="sidebar-widget latest-tpl-4">
                                    <h5 class="spanborder widget-title">
                                        <span>Popular in {{ category }}</span>
                                    </h5>
                                    <ol>
                                        <li class="d-flex" v-for="item2 in mostLiked" :key="item2.index">
                                            <div class="post-count">01</div>
                                            <div class="post-content">
                                                <h5 class="entry-title mb-3"><a href="single.html">{{ item2.title }}</a></h5>
                                                <div class="entry-meta align-items-center">
                                                    <a href="author.html">{{ item2.owner }}</a> in <a href="archive.html">Random</a><br>                                    
                                                    <span>{{ item2.updated_date }}</span>
                                                    <span class="middotDivider"></span>
                                                    <span class="readingTime" :title="item2.reading.text">{{ item2.reading.text }}</span>
                                                    <span class="svgIcon svgIcon--star">
                                                        <svg class="svgIcon-use" width="15" height="15">
                                                            <path d="M7.438 2.324c.034-.099.09-.099.123 0l1.2 3.53a.29.29 0 0 0 .26.19h3.884c.11 0 .127.049.038.111L9.8 8.327a.271.271 0 0 0-.099.291l1.2 3.53c.034.1-.011.131-.098.069l-3.142-2.18a.303.303 0 0 0-.32 0l-3.145 2.182c-.087.06-.132.03-.099-.068l1.2-3.53a.271.271 0 0 0-.098-.292L2.056 6.146c-.087-.06-.071-.112.038-.112h3.884a.29.29 0 0 0 .26-.19l1.2-3.52z"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div></div></div> <!--col-md-4-->
                        </div>
                    </div> <!--content-widget-->
                </div>
            </main>
    </div>
</template>
<script>
import axios from 'axios'
import $ from 'jquery'
import Navbar from '@/components/layouts/Navbar.vue'
import moment from 'moment'
import readingTime from 'reading-time'

export default {
    data() {
        return {
            data: null,
            random: null,
            category: '',
            mostLiked: ''
        }
    },
    mounted() {
        this.category = this.$route.params.namaCategory
        document.title = `Category ${this.category} - Random`
        this.getData()
    },
    components: {
        Navbar,
    },
    methods: {
        async getData() {
            const data = await axios.get('category/detail?url='+this.$route.params.namaCategory)
            let arrayData = Array()
            let arrayDataMostLiked = Array()
            data.data.data[0].categoryPost.forEach(element => {
                let toPush = null
                const startDesc = element.description.indexOf('<p class="blog_post_text text-left">')
                if (startDesc < 0) {
                    toPush = 'Nothing paragraph in here'  
                } else {
                    const jadi_string = $(element.description).text().trim().substr(0, 50)
                    toPush = jadi_string
                }
                const time = readingTime($(element.description).text().trim())
                const data = {
                    id: element.id,
                    title: element.title,
                    description: toPush,
                    thumbail_url: (element.thumbail_url) ? element.thumbail_url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAY4UUVKjiP3MjXyNxAW3FC5CddlG2YLFP31nvwQsN8_aww6DmWTHzdoZd2rvBE_3YlOY&usqp=CAU',
                    owner: element.ownerPosting.username,
                    url: (element.url_perma) ? element.url_perma : '/',
                    updated: (element.date_updated != "non") ? true : false,
                    owner_image: element.ownerPosting.profile_photo,
                    updated_date: (element.date_updated != "non") ? moment(element.date_updated).format('MMMM D') : moment(element.date_created).format('MMMM D'),
                    reading: time
                }
                arrayData.push(data)
            });
            const dataLiked = data.data.mostLiked[0].categoryPost.splice(0,3)
            dataLiked.forEach(element => {
                let toPush = null
                const startDesc = element.description.indexOf('<p class="blog_post_text text-left">')
                if (startDesc < 0) {
                    toPush = 'Nothing paragraph in here'  
                } else {
                    const jadi_string = $(element.description).text().trim().substr(0, 50)
                    toPush = jadi_string
                }
                const time = readingTime($(element.description).text().trim())
                const data = {
                    id: element.id,
                    title: element.title,
                    description: toPush,
                    thumbail_url: (element.thumbail_url) ? element.thumbail_url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAY4UUVKjiP3MjXyNxAW3FC5CddlG2YLFP31nvwQsN8_aww6DmWTHzdoZd2rvBE_3YlOY&usqp=CAU',
                    owner: element.ownerPosting.username,
                    url: (element.url_perma) ? element.url_perma : '/',
                    updated: (element.date_updated != "non") ? true : false,
                    owner_image: element.ownerPosting.profile_photo,
                    updated_date: (element.date_updated != "non") ? moment(element.date_updated).format('MMMM D') : moment(element.date_created).format('MMMM D'),
                    reading: time
                }
                arrayDataMostLiked.push(data)
            });
            this.mostLiked = arrayDataMostLiked
            this.data = arrayData
            this.getOne(arrayData)
        },
        getOne(data) {
            this.random = data[Math.floor(Math.random() * data.length)]
        },

    }
}
</script>
<style scoped>

</style>